import _definePage_default_0 from '/home/infobex/corrigo/frontend/src/pages/map.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'

export const routes = [
  {
    path: '/analytics',
    /* internal name: '/analytics' */
    /* no component */
    children: [
      {
        path: '',
        name: 'analytics-root',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/analytics/index.vue'),
        /* no children */
        meta: {
          "requiresAdminRights": true,
          "isDashboardItem": true,
          "displayName": "Statisztikák",
          "icon": "chart-user",
          "index": 10000
        }
      },
      {
        path: 'subscriptions',
        name: 'analytics-subscriptions',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/analytics/subscriptions.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/dashboard',
    name: 'admin-dashboard',
    component: () => import('/home/infobex/corrigo/frontend/src/pages/dashboard.vue'),
    /* no children */
    meta: {
      "requiresAuth": true,
      "displayName": "Dashboard",
      "icon": "chart-mixed",
      "index": -1,
      "isDashboardItem": true,
      "requiresAdminRights": true
    }
  },
  {
    path: '/igazolas',
    name: 'usage-reporting',
    component: () => import('/home/infobex/corrigo/frontend/src/pages/igazolas.vue'),
    /* no children */
    meta: {
      "requiresAuth": true,
      "displayName": "Igazolások",
      "icon": "file-certificate",
      "index": 15000,
      "isDashboardItem": true
    }
  },
  _mergeRouteRecord(
  {
    path: '/map',
    name: 'session-viewer',
    component: () => import('/home/infobex/corrigo/frontend/src/pages/map.vue'),
    children: [
      {
        path: 'logins/:login_id',
        name: 'session-viewer-login',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/map/logins.[login_id].vue'),
        props: true,
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": true,
      "displayName": "Térkép",
      "icon": "map-location-dot",
      "index": 250,
      "isDashboardItem": true,
      "hideOnMobile": true
    }
  },
  _definePage_default_0
  ),
  {
    path: '/orders',
    /* internal name: '/orders' */
    /* no component */
    children: [
      {
        path: '',
        name: 'ordermgmt',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/orders/index.vue'),
        /* no children */
        meta: {
          "requiresAdminRights": true,
          "isDashboardItem": true,
          "displayName": "Rendelések",
          "icon": "briefcase",
          "index": 12000
        }
      },
      {
        path: ':order_id',
        /* internal name: '/orders/[order_id]' */
        /* no component */
        children: [
          {
            path: '',
            name: 'ordermgmt-overview',
            component: () => import('/home/infobex/corrigo/frontend/src/pages/orders/[order_id]/index.vue'),
            props: true,
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/profile',
    name: 'user-profile-root',
    component: () => import('/home/infobex/corrigo/frontend/src/pages/profile.vue'),
    children: [
      {
        path: 'logins',
        name: 'user-profile-logins',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/profile/logins.vue'),
        alias: [],
        /* no children */
        meta: {
          "requiresAuth": true,
          "displayName": "Fiók"
        }
      },
      {
        path: 'orders',
        name: 'user-profile-orders',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/profile/orders.vue'),
        props: true,
        /* no children */
      },
      {
        path: 'settings',
        name: 'user-profile-settings',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/profile/settings.vue'),
        props: true,
        /* no children */
      }
    ],
    meta: {
      "requiresAuth": true,
      "displayName": "Fiók",
      "icon": "address-card",
      "index": 0,
      "isDashboardItem": true
    }
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('/home/infobex/corrigo/frontend/src/pages/subscriptions.vue'),
    /* no children */
    meta: {
      "requiresAdminRights": true,
      "requiresAuth": true,
      "displayName": "Lejáró előfizetések",
      "icon": "calendar-range",
      "index": 0,
      "isDashboardItem": true
    }
  },
  {
    path: '/user-management',
    /* internal name: '/user-management' */
    /* no component */
    children: [
      {
        path: '',
        name: 'user-management',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/index.vue'),
        /* no children */
        meta: {
          "requiresAdminRights": true,
          "isDashboardItem": true,
          "displayName": "Felhasználók kezelése",
          "icon": "users-gear",
          "index": 9000
        }
      },
      {
        path: ':user_id',
        name: 'admin-user-data',
        component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id].vue'),
        props: true,
        children: [
          {
            path: 'account',
            name: 'usermgmt-account-settings',
            component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id]/account.vue'),
            props: true,
            /* no children */
          },
          {
            path: 'forms/:schema_id?',
            name: 'usermgmt-account-metadata',
            component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id]/forms.[[schema_id]].vue'),
            props: true,
            /* no children */
          },
          {
            path: 'logins',
            name: 'admin-user-logins',
            component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id]/logins.vue'),
            props: true,
            children: [
              {
                path: ':login_id',
                /* internal name: '/user-management/[user_id]/logins/[login_id]' */
                /* no component */
                children: [
                  {
                    path: 'forms/:schema_id?',
                    name: 'login-forms',
                    component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id]/logins/[login_id]/forms.[[schema_id]].vue'),
                    props: true,
                    /* no children */
                  },
                  {
                    path: 'order',
                    name: 'login-place-order',
                    component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id]/logins/[login_id]/order.vue'),
                    props: true,
                    /* no children */
                  }
                ],
              }
            ],
          },
          {
            path: 'orders',
            name: 'usermgmt-order-history',
            component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id]/orders.vue'),
            props: true,
            /* no children */
          },
          {
            path: 'subscriptions',
            name: 'admin-user-history',
            component: () => import('/home/infobex/corrigo/frontend/src/pages/user-management/[user_id]/subscriptions.vue'),
            props: true,
            /* no children */
          }
        ],
      }
    ],
  }
]
