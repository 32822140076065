<script setup lang="ts">
import { useAppCore } from "@/stores/core"
import { VIcon } from "@infobex/vue-ui-lib/core"
import { useDashboardItems } from "./useDashboardItems"
import { useMediaQuery } from "@vueuse/core"

// const router = useRouter()
const { state, actions } = useAppCore()

const navItems = useDashboardItems()
const isMobileScreen = useMediaQuery("(max-width: 1023px)")

console.log(navItems)
</script>
<template>
	<aside class="dashboard-nav">
		<template v-for="item in navItems" :key="item.name">
			<RouterLink
				v-if="!(item.meta.hideOnMobile && isMobileScreen)"
				:to="item"
				exact-active-class=""
				class="dashboard-link"
				active-class="active"
			>
				<VIcon :i="item.meta.icon" size="24" />
				<span class="dashboard-link-text">{{ item.meta.displayName }}</span>
			</RouterLink>
		</template>
	</aside>
</template>
<style lang="scss">
aside.dashboard-nav {
	// background-color: whitesmoke;
	@apply bg-neutral-900 text-white;
	@apply shadow-lg;
	@apply flex flex-col;
	@apply text-sm font-light;
	@apply w-min;
	@apply h-full;

	@apply p-0;

	// @apply lg:flex-row;
	@apply lg:flex-row lg:w-full lg:h-fit;
}

span.dashboard-link-text {
	// @apply md:text-xs md:font-bold;
	// display: none;
	@apply sm:hidden;
	@apply lg:w-min;
	@apply text-center xl:text-left;
}

.dashboard-link {
	@apply flex flex-col xl:gap-3;
	@apply items-center;
	@apply p-3 hd:px-2 hd:py-4;
	@apply transition-colors;
	@apply xl:justify-start xl:items-center xl:flex-row xl:text-xs;
	@apply lg:flex-1;
	@apply lg:flex-row lg:justify-center;
	// @apply md:flex-col;

	i {
		// @apply p-5;
		flex-shrink: 0;
	}

	&.active {
		// text-shadow: 0px 0px 3px rgba(var(--c-primary-l) / 20%);
		background-color: rgba(var(--c-primary-d) / 100%);
		pointer-events: none;
		// color: rgba(var(--c-primary-l) / 100%);
		// text-shadow: 0px 0px 3px #fffa;
	}
}
</style>
