<script setup lang="ts">
import { useAppCore, USER_API } from "@/stores/core"
import { computed, reactive, ref } from "vue"
import { Input, Form } from "@/components/ui"
import { VIcon, VButton } from "@infobex/vue-ui-lib/core"

import type { FormResolver } from "../ui/Form/Form.vue"
import { sleep } from "../../utils/timers"
import { useLocalStorage } from "@vueuse/core"
import { LOCAL_STORAGE_KEYS } from "../../utils/local_storage_keys"
import { useModal } from "@infobex/vue-ui-lib/unstable"
import MigrationInfoDialog from "@/components/dialogs/MigrationInfoDialog.vue"
import { useRoute } from "vue-router"

defineProps<{ inputClasses?: string }>()
const { state, actions } = useAppCore()

enum SCREEN {
	LOGIN,
	RESET
}

const loginParams = reactive({
	username: "",
	password: "",
	email: ""
})

const emit = defineEmits<{
	(e: "success"): void
	(e: "error", error: any): void
}>()

const hasError = ref<any>(null)
const screen = ref(SCREEN.LOGIN)
// const referrer = document.referrer

const query = useRoute().query

if (query.url == "igazolas.corrigo.hu") {
	const viewed_dialog = useLocalStorage(LOCAL_STORAGE_KEYS.VIEWED_MIGRATION_INFO_DIALOG, false)
	if (!viewed_dialog.value) {
		useModal(MigrationInfoDialog, {})
	}
}

if (query.reset !== undefined) {
	screen.value = SCREEN.RESET
}

const isValid = computed(() => {
	return loginParams.username && loginParams.password
})

async function handleSubmit(params, ctx: FormResolver) {
	try {
		hasError.value = null
		await actions.signIn(params)
		emit("success")
	} catch (e: any) {
		if (e.code === "NOT_FOUND") ctx.reject("Hibás felhasználónév vagy jelszó!")
		else ctx.reject(e)
	}
}

async function requestPasswordReset(_: any, formCtx: FormResolver) {
	try {
		USER_API.requestPasswordReset({ RequestPasswordResetRequest: { email: loginParams.email } })
		await sleep(1500)
		formCtx.resolve(
			"A megadott e-mail címre rendszerünk küldött Önnek egy levelet. Az abban található link visszairányítja oldalunkra, ahol beállíthatja jelszavát."
		)
	} catch (e) {
		formCtx.reject("Szerveroldali hiba")
	}
}
</script>
<template>
	<Form v-if="screen === SCREEN.LOGIN" v-slot="{ disabled, error }" class="login-form" @submit="handleSubmit">
		<h4 class="text-xl text-primary">BEJELENTKEZÉS</h4>
		<!--
		<p class="text-xl font-light">
			Van már fiókja?<br />
			Jelentkezzen be!
		</p>
		-->

		<!--
		<p class="text-base font-light">
			A bejelentkezéshez adja meg a<br />
			felhasználónevét és a jelszavát.
		</p>
		-->

		<Input v-model="loginParams.username" :class="inputClasses" name="username" placeholder="Felhasználónév" />

		<Input
			v-model="loginParams.password"
			:class="inputClasses"
			name="password"
			type="password"
			placeholder="Jelszó"
		/>

		<p v-if="error" class="text-base font-light text-red-500 bg-black bg-opacity-70 px-4 py-2 rounded font-medium">
			{{ error }}
		</p>
		<VButton type="submit" class="self-stretch" color="primary" variant="outlined" :disabled="!isValid || disabled">
			Bejelentkezés
			<template #suffix><VIcon i="circle-arrow-right" /></template>
		</VButton>
		<VButton
			class="self-stretch"
			variant="link"
			@click="
				() => {
					screen = SCREEN.RESET
				}
			"
		>
			Jelszó helyreállítása...
		</VButton>
	</Form>

	<Form
		v-if="screen === SCREEN.RESET"
		v-slot="{ disabled, error, message }"
		class="login-form"
		disallow-resubmit
		@submit="requestPasswordReset"
	>
		<h4 class="text-xl text-sky-600">FIÓK HELYREÁLLÍTÁSA</h4>

		<p class="text-base font-light">
			Ügyfélfiókja jelszavának helyreállítása érdekében, kérjük, adja meg regisztrált e‑mail címét, és kövesse az
			e‑mailben kapott utasításokat.
		</p>

		<Input
			v-model="loginParams.email"
			:class="inputClasses"
			name="email"
			type="email"
			placeholder="E-mail cím"
			autocomplete="email"
			required
		/>
		<VButton
			class="self-stretch"
			color="info"
			variant="outlined"
			:disabled="!loginParams.email || disabled"
			type="submit"
		>
			E‑mail küldése
			<template #suffix>
				<VIcon i="paper-plane" variant="solid" />
			</template>
		</VButton>
		<!-- {{ error }} -->
		<p v-if="error" class="text-base font-light text-red-500">
			{{ error }}
		</p>
		<p v-if="message" class="text-base font-light text-white">
			{{ message }}
		</p>
		<VButton
			variant="link"
			color="primary"
			class="self-stretch"
			@click="
				() => {
					screen = SCREEN.LOGIN
				}
			"
		>
			Vissza a bejelentkező oldalra
			<template #suffix>
				<VIcon i="arrow-rotate-left" variant="light" />
			</template>
		</VButton>
	</Form>
</template>
<style lang="scss">
form.login-form {
	@apply items-center justify-center;

	@apply flex flex-col;
	@apply gap-5;
	@apply md:items-center md:gap-6 md:text-xl;

	// margin: auto;
	width: 18rem;

	.input {
		width: 100%;
	}
	// --input-width: 16rem;
	// input {
	// 	width: 16rem;
	// }
}
</style>
