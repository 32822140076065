<script setup lang="ts">
import { Card } from "../ui"
import { VIcon, VButton } from "@infobex/vue-ui-lib/core"

defineProps<{
	title?: string
	message?: string | string[]
	html?: boolean
	icon?: string | null
	i?: string | null
	color: "error" | "warning" | "info" | "success" | "neutral"
	hideActions?: boolean
}>()

const emit = defineEmits<{
	(c: "resolve", v?: any): any
	(c: "reject", v?: any): any
}>()

function handleResolve() {
	emit("resolve", null)
}
</script>
<template>
	<Card color="white" class="dialog" :class="color">
		<!-- <slot name="icon"> -->
		<VIcon v-if="icon || i" size="32" variant="duotone" :i="i ?? icon" class="dialog-icon" />
		<!-- </slot> -->
		<header v-if="title" class="dialog-header">
			{{ title }}
		</header>
		<main v-if="!$slots.override" class="dialog-body">
			<slot>
				<template v-if="typeof message === 'string'">
					<p v-if="!html">
						{{ message }}
					</p>
					<!-- v-html is probably safe, since we're supposed to only passing it content hard-coded into the frontend -->
					<div v-if="html" v-html="message" />
				</template>
				<template v-if="Array.isArray(message)">
					<p v-for="(paragraph, idx) in message" :key="idx" class="dialog-paragraph">
						{{ paragraph }}
					</p>
				</template>
			</slot>
		</main>
		<slot name="override" />
		<footer v-if="!$slots.override" class="dialog-footer">
			<slot name="actions">
				<VButton color="neutral" variant="filled" class="px-16" @click="handleResolve">OK</VButton>
			</slot>
		</footer>
	</Card>
</template>
<style lang="scss">
.dialog {
	border-top: 16px solid rgba(var(--accent-color) / 100%);
	--radius: 8px;
	gap: 1rem;
	padding: 0;
	// text-align: center;
	align-items: stretch;
	border-radius: var(--radius);
	// max-width: calc(100vw - 5rem);
	// max-height: calc(100vh - 5rem);
	max-height: 100%;
	max-width: 100%;

	// overflow: auto;

	@apply transition-all;
	@apply shadow-lg;
	@apply sm:inset-0 sm:rounded-none md:items-center sm:overflow-auto;
	// position: absolute;
	//

	&.error {
		// @apply text-red-600
		--accent-color: 220 38 38;
	}

	&.warning {
		--accent-color: var(--c-warning);
	}

	&.neutral {
		--accent-color: var(--c-neutral);
	}

	&.info {
		--accent-color: var(--c-info);
	}

	&.success {
		--accent-color: var(--c-primary);
	}

	.dialog-icon {
		position: absolute;
		left: 50%;
		// transform: translateY(50%) translateX(-50%);
		// bottom: 100%;
		transform: translateY(-50%) translateX(-50%);
		top: 0px;
		background-color: white;
		color: rgba(var(--accent-color));
		border-radius: 64px;
		border: 4px solid rgba(var(--accent-color) / 100%);
		--blocksize: 72px;
		@apply sm:relative sm:transform-none sm:left-0 sm:mb-0 sm:mt-3;
		@apply flex-shrink-0;
		z-index: 100;
		// @apply sm:hidden;
		// @apply shadow-md;
	}

	header.dialog-header {
		@apply text-3xl px-12 mx-12 py-0;
		@apply text-neutral-800;
		font-weight: 500;
		@apply sm:mx-0 sm:px-2;
		@apply sm:border-b;
		@apply sm:text-2xl;
		@apply mt-6;
		@apply text-center;
		// @apply border-b border-neutral-100;;;;
	}

	.dialog-icon ~ .dialog-header {
		@apply mt-12 sm:mt-0;
	}

	.dialog-body {
		@apply px-12 sm:px-0;
		@apply py-8;
		@apply text-lg;
		@apply flex-grow;
		@apply overflow-auto;
	}

	footer.dialog-footer {
		@apply flex items-center justify-center;
		@apply bg-neutral-100;
		@apply border-t border-neutral-200;
		@apply p-5;
		@apply gap-8;
		@apply w-full;
		border-bottom-left-radius: var(--radius);
		border-bottom-right-radius: var(--radius);

		.button {
			// @apply px-16;
			font-weight: 600;
			// text-transform: uppercase;
		}
	}
}

.dialog-paragraph:not(:last-child) {
	@apply mb-2;
}
</style>
