<script setup lang="ts">
import { VFormControl, VIcon } from "@infobex/vue-ui-lib"
import { useModal } from "@infobex/vue-ui-lib/unstable"

import dayjs, { Dayjs } from "dayjs"
import Dialog from "./Dialog.vue"

const modelValue = defineModel<Dayjs>()

const props = defineProps<{
	minDate?: Dayjs
	maxDate?: Dayjs
}>()

let isOpen = false

async function openDialog() {
	if (isOpen) return
	isOpen = true

	const date = await useModal(Dialog, {
		minDate: props.minDate,
		maxDate: props.maxDate,
		defaultValue: modelValue.value
	})

	modelValue.value = date
	isOpen = false
}

const formatString = "YYYY. MMM. D."
</script>
<template>
	<div @click="openDialog">
		<VFormControl :model-value="modelValue?.format(formatString) ?? '-'" name="date">
			<template #prefix>
				<VIcon i="calendar" />
			</template>
		</VFormControl>
	</div>
</template>
<style lang="scss"></style>
