import dayjs, { Dayjs } from "dayjs"

type DateLike = Date | Dayjs | string

export function prettyPrintDateRange(d1: DateLike, d2: DateLike, fullDateFormat = "YYYY. MMM. D.") {
	d1 = dayjs(d1)
	d2 = dayjs(d2)
	if (d1.valueOf() === d2.valueOf()) return dayjs(d1).format(fullDateFormat)
	//parse as dayjs objects
	const [start, end] = [dayjs(d1), dayjs(d2)]
	if (start.isSame(end, "day")) return dayjs(d1).format(fullDateFormat)

	let formatString = "D."
	if (d1.year() !== d2.year()) {
		formatString = fullDateFormat
	} else if (d1.month() !== d2.month()) {
		formatString = "MMM. D."
	}
	return `${start.format(fullDateFormat)} - ${end.format(formatString)}`
}

export function prettyPrintDateRange2(d1: DateLike, d2: DateLike, fullDateFormat = "YYYY. MMM. D. HH:mm") {
	d1 = dayjs(d1)
	d2 = dayjs(d2)
	if (d1.valueOf() === d2.valueOf()) return dayjs(d1).format(fullDateFormat)
	//parse as dayjs objects
	const [start, end] = [dayjs(d1), dayjs(d2)]
	// if (start.isSame(end, "day")) return $`dayjs(d1).format(fullDateFormat)`

	let formatString = "MMM. D. HH:mm"
	if (start.year() !== end.year()) {
		formatString = fullDateFormat
	} else if (start.month() !== end.month()) {
		formatString = "MMM. D. HH:mm"
	} else if (start.isSame(end, "day")) {
		formatString = "HH:mm"
	}
	return `${start.format(fullDateFormat)} - ${end.format(formatString)}`
}
