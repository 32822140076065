import { useAppCore } from "@/stores/core"

export function useConsoleCommands() {
	const store = useAppCore()

	const wnd = window as any

	if (!wnd.enable_admin_controls) {
		wnd.enable_admin_controls = store.actions.enableAdminControls
	}
}
