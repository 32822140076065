import { useAppCore } from "@/stores/core"
import { useRouter } from "vue-router"

export function useDashboardItems() {
	const { state } = useAppCore()
	return useRouter()
		.getRoutes()
		.filter(({ name, meta: { isDashboardItem, requiresAdminRights } }) => {
			if (!isDashboardItem) return false
			// console.log(name, requiresAdminRights)
			if (requiresAdminRights == true) return state.isAdmin
			return true
			// else return utils.userHasRole(requiresRole)
		})
		.sort((a, b) => a.meta.index - b.meta.index)
}
