<script setup lang="ts">
import { computed, ref } from "vue"
import { useRouter } from "vue-router"
import NavBarLink from "./NavBarLink.vue"
import NavBarUserInfo from "./NavBarUserInfo.vue"
import NavBarMobile from "./NavBarMobile.vue"
import { VIcon } from "@infobex/vue-ui-lib/core"
import { Brand } from ".."
import { useAppCore } from "@/stores/core"
import { useNavbarItems } from "./composables"
import OrganizationSelector from "../OrganizationSelector.vue"
import { useMediaQuery } from "@vueuse/core"
import { useScreenSizes } from "../../../utils/responsive"

const router = useRouter()

// const menuOpen = ref(false)

const { state } = useAppCore()

function toggle() {
	const cr = router.currentRoute.value
	if (!cr.query.navmenu) {
		router.replace({ ...cr, query: { navmenu: "true" } })
	} else {
		router.replace({ ...cr, query: {} })
	}
}

const navItems = computed(() => useNavbarItems().filter(itm => (itm.requiresAuth ? state.currentUser !== null : true)))

const { md, sm } = useScreenSizes()

// console.log(sizes)
</script>
<template>
	<nav id="navbar">
		<div v-if="sm" class="brand" />

		<OrganizationSelector v-if="md" />
		<!-- {{ sizes }} -->
		<!-- <Brand/> -->
		<!-- <div class="navbar-content"> -->
		<!-- <template v-for="item in navItems">
				<NavBarLink v-if="!item.isMenuItem" :to="item.route" :menuRoot="item.isMenuRoot">
					{{ item.displayName }}
				</NavBarLink>
			</template> -->
		<!-- </div> -->
		<NavBarUserInfo v-if="state.currentUser" />
		<!-- <VButton @click="toggle" class="hidden lg:block">
			<VIcon :i="$route.query.navmenu ? 'close' : 'bars'" size="32" />
		</VButton> -->
	</nav>
</template>
<style lang="scss">
#navbar {
	@apply flex justify-between items-center;
	@apply bg-black;
	// padding-block: 14px;
	padding-inline: 18px;
	@apply text-white;
	// @apply backdrop-filter backdrop-blur;
	// @apply shadow-lg;
	// position: absolute;
	z-index: 100;
	top: 0px;
	// width: 100%;
	@apply overflow-y-visible overflow-x-clip;

	@apply py-4;
	@apply relative xl:sticky;

	@apply justify-between;
}

.brand {
	background-image: url("/img/corrigo_white.png");
	height: 36px;
	width: 200px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center left;
	@apply md:hidden;
}

.navbar-content {
	@apply flex items-center;
	// @apply gap-6;
	// @apply lg:hidden;

	@apply text-xl;
	@apply font-bold;
	// @apply lg:
}
</style>
