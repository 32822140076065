import { useAppCore } from "@/stores/core"
import { createRouter, createWebHistory } from "vue-router/auto"

declare module "vue-router" {
	interface RouteMeta {
		hide?: boolean
		displayName?: string
		icon?: string
		rootId?: string
		index?: number
		isDashboardItem?: boolean
		isNavbarItem?: boolean
		isMenuRoot?: string
		isMenuItem?: string
		showFooter?: boolean
		showContact?: boolean
		requiresAuth?: boolean
		requiresRole?: string
		requiresRoles?: string[]
		requiresAdminRights?: boolean
		hideOnMobile?: boolean
		hideLoginPage?: boolean
	}
}

const router = createRouter({
	history: createWebHistory()
})

/** Auth guard */
// router.beforeEach(async route => {
// 	if (route.meta.requiresAuth) {
// 		const { state } = useAppCore()
// 		await state.loaded
// 		if (state.currentUser === null) {
// 			return {
// 				name: "application-root",
// 				replace: true
// 			}
// 		}
// 	}
// })

/**	Admin guard */
router.beforeEach(async route => {
	if (route.meta.requiresAdminRights) {
		const { state } = useAppCore()
		await state.loaded
		if (!state.isAdmin && state.currentUser !== null) {
			return {
				name: "application-root",
				replace: true
			}
		}
	}
})

export default router
