<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs"
import { Card } from ".."
import { VDateRange } from "@infobex/vue-ui-lib"
import { ref } from "vue"
import { VButton } from "@infobex/vue-ui-lib/core"

const props = defineProps<{
	minDate?: Dayjs
	maxDate?: Dayjs
	defaultValue?: [Dayjs, Dayjs]
}>()

const emit = defineEmits<{
	(c: "resolve", v: [Dayjs, Dayjs]): any
	(c: "reject"): any
}>()

const innerValue = ref<[Dayjs, Dayjs]>(props.defaultValue)

function resolveDialog() {
	emit("resolve", innerValue.value)
}
</script>
<template>
	<div class="bg-white items-center flex flex-col pb-8">
		<VDateRange v-model="innerValue" :min-date="props.minDate" :max-date="props.maxDate" color="info" />

		<div class="flex flex-row gap-4">
			<VButton size="lg" color="info" variant="subdued" @click="emit('reject')">Bezár</VButton>
			<VButton size="lg" color="info" variant="subdued" @click="resolveDialog">OK</VButton>
		</div>
	</div>
</template>
<style lang="scss"></style>
