<script setup lang="ts">
import { VButton, VIcon } from "@infobex/vue-ui-lib/core"

import Checkbox from "../ui/Checkbox.vue"
import GenericDialog from "./GenericDialog.vue"
import { useLocalStorage } from "@vueuse/core"
import { LOCAL_STORAGE_KEYS } from "@/utils/local_storage_keys"
import { Icon } from "@/components/ui"
import StringCopy from "../ui/StringCopy.vue"

// const emit =
defineEmits<{
	(c: "resolve", v: void): any
	(c: "reject", v: void): any
}>()

const remember_dialog = useLocalStorage(LOCAL_STORAGE_KEYS.VIEWED_MIGRATION_INFO_DIALOG, false)
// if (remember_dialog.value) emit("resolve")
</script>
<template>
	<GenericDialog color="info" i="info" title="Tisztelt Ügyfelünk!">
		<!-- <template #override> -->
		<div style="max-width: 40rem" class="asdfasdf">
			<p>
				Ezúton tájékoztatjuk Önt, hogy portálunkat továbbfejlesztettük, mellyel további szolgáltatásokat tudunk az
				Ön számára biztosítani: gyors és hatékony módon tudják a szolgáltatás megrendeléseiket leadni, dátum illetve
				településnév megadásával munkameneteiket megkeresni és azt térképen megjeleníteni illetve azokról – a
				mindenkori jogszabályoknak megfelelő – igazolást letölteni.
			</p>

			<p>
				Mivel Ön rendelkezett eddig is az Igazolásletöltő szolgáltatásunkhoz regisztrációval, így az új portálhoz is
				létezik már ügyfélfiókja. Belépéshez kérjük végezze el az alábbiakat:
			</p>

			<p>
				Továbblépést követően adja meg az eddigi regisztrációjában megadott e-mail címét.Ezután kap egy e-mailt
				tőlünk, melyben található link visszairányítja oldalunkraA megjelenő oldalon lesz látható - a későbbi
				belépéshez szükséges - CORRIGO Ügyfélfiókjának felhasználóneve és itt adhatja meg az ehhez tartozó jelszót.
			</p>

			<p
				class="bg-sky-500 bg-opacity-10 py-2 px-4 flex items-baseline gap-3 font-medium text-neutral-600"
				style="font-size: 0.9em"
			>
				<VIcon i="circle-info" class="text-sky-600" variant="solid" />
				<i>
					Fontos hangsúlyozni, hogy az Ön műszerén beállított felhasználónév és jelszó NEM változik, a műszerén
					semmilyen változtatást nem kell eszközölnie. A most beállított jelszó kizárólag a webes portálba való
					belépését teszi lehetővé.
				</i>
			</p>

			<p>
				A későbbi könnyebb belépés érdekében javasoljuk, hogy böngészőjének engedélyezze a CORRIGO Ügyfélfiókja
				felhasználónevének és jelszavának elmentését.
			</p>

			<p class="mb-8">
				Együttműködését előre is köszönjük.
				<br />
				Üdvözlettel:
				<br />
				A CORRIGO Csapata
			</p>
		</div>

		<template #actions>
			<Checkbox v-model="remember_dialog" label="Ne jelenjen meg többet ez az üzenet." class="text-sm font-bold" />
			<VButton variant="filled" color="info" @click="() => $emit('resolve')">Megértettem</VButton>
		</template>
		<!-- </template> -->
	</GenericDialog>
</template>
<style lang="scss">
.asdfasdf {
	@apply text-justify flex flex-col gap-8;
	line-height: normal;
}
</style>
