<script setup lang="ts">
import { computed, ref } from "vue"
import dayjs, { Dayjs } from "dayjs"
import { VLoader, VButton } from "@infobex/vue-ui-lib"
import { RTXNET_API } from "@/stores/apis"
import { ModalDateSelector, Pending } from "@/components/ui"

import EmbeddableChartContainer from "../EmbeddableChartContainer.vue"
import Chart from "./Chart.vue"

type DateRange = {
	start_date: Dayjs
	end_date: Dayjs
}
const date = ref(dayjs())

const source = ({ date }: { date: Dayjs }) => {
	return RTXNET_API.getResidualHistory({
		start_date: date.startOf("day").toISOString(),
		end_date: date.endOf("day").toISOString()
	})
}

const dateRange = ref<DateRange>({
	start_date: dayjs(),
	end_date: dayjs()
})

const selected = ref("ionospheric")

RTXNET_API.getI95HistoryAvailability().then(res => {
	dateRange.value.start_date = dayjs(res.start_date)
	dateRange.value.end_date = dayjs(res.end_date)
})

function handleSelectChart(_e: Event, chart_key: string) {
	selected.value = chart_key
}
</script>
<template>
	<EmbeddableChartContainer
		:title="'ionospheric' ? 'Előre jelzett ionoszférikus hiba (IRIM)' : 'Előre jelzett geometrikus hiba (GRIM)'"
	>
		<template #controls>
			<VButton
				:variant="selected == 'ionospheric' ? 'filled' : 'subdued'"
				value="ionospheric"
				@click="handleSelectChart"
			>
				{{ "Ionoszférikus" }}
			</VButton>
			<VButton
				value="geometric"
				:variant="selected == 'geometric' ? 'filled' : 'subdued'"
				@click="handleSelectChart"
			>
				{{ "Geometrikus" }}
			</VButton>
			<ModalDateSelector
				v-model="date"
				:min-date="dayjs(dateRange.start_date)"
				:max-date="dayjs(dateRange.end_date)"
			/>
		</template>
		<template #chart>
			<VLoader v-slot="{ data, pending }" :source="source" :args="{ date }" :fallback="[]">
				<Chart
					:labels="data.map(d => dayjs(d.t).format('H:mm'))"
					:values="data.map(d => (selected === 'geometric' ? d.g : d.i))"
				/>
				<Pending v-if="pending" />
			</VLoader>
		</template>
		<template #help>
			<div class="px-10 text-justify text-sm">
				<p>
					Az ionoszférikus maradék lineáris része az interpolációval csökken, a nemlineáris rész, ami az
					interpolációval nincs modellezve, megmarad a rovernek küldött hálózati korrekcióban. Azaz, minél
					lineárisabb az ionoszféra, annál nagyobb pontosságú az interpoláció és annál kevesebb hibát „lát” a
					rover. Így az ionoszférikus linearitás mutatja meg a hálózat üzemeltetőjének a hálózaton belüli maradék
					hibákat, továbbá jó becslést adhat az interpolációs hibára a terepi felhasználóknak. Az ionoszférikus
					linearitás egyik mutatója az Ionoszférikus Maradék Integritás Monitoring - IONOSPHERIC RESIDUAL INTEGRITY
					MONITORING - (IRIM) illetve a Geometrikus Maradék Integritás Monitoring - GEOMETRIC RESIDUAL INTEGRITY
					MONITORING (GRIM) amelyek úgy kerülnek kiszámításra, hogy az egyik referenciaállomást kihagyjuk az
					interpolációból, majd az interpolációs eredmények összehasonlításra kerülnek az összes műhold észleléssel
					ezen referenciaállomáson a valódi mérési adatokkal. Ezt követően számításra kerül a súlyozott RMS egy
					epoch-on az összes műholdra vonatkoztatva, majd összegyűjti a rendszer az egy óra alatt számított
					súlyozott RMS-eket. Tehát kiszámításra kerül az interpoláció hibája az ionoszférikus és geometrikus
					összetevőktől függetlenül és súlyozott RMS-t biztosít az összes műholdra vonatkoztatva. A módszert az
					interpoláció maradék integritás monitoringjának tekinthetjük.
				</p>
				<p>
					A hálózati processzor modul grafikus áttekintést ad a rover várható teljesítményéről az IRIM és a GRIM
					diagrammokkal. Óránként összesíti valamennyi bázisállomás RMS értékét, az ionoszférikus valamint
					geometriai összetevőkkel 95%-os interpolációs bizonytalansággal. Az adott órán belüli legmagasabb maradék
					érték jelenik meg. Ezek az értékek az előre jelzett ionoszférikus és geometriai hibákban kerülnek
					bemutatásra a Predicted Ionospheric and Geometric Errors diagramokon.
				</p>
			</div>
		</template>
	</EmbeddableChartContainer>
</template>
