import { defineStore } from "pinia"
import { computed, reactive, ref } from "vue"
import type { UiConfig, UserData, SignInOperationRequest } from "@infobex/corrigo-api"
import { configApi, USER_API } from "./apis"
import { useSessionStorage } from "@vueuse/core"

import { LOCAL_STORAGE_KEYS as LS } from "@/utils/local_storage_keys"

// import { DataProvider } from "@/components/utils/DataSource"
// import type { GetSessionsForLoginRequest } from "@infobex/corrigo-api"

export * from "./apis"

const ADMIN_ROLES = ["ADMIN", "ORG_ADM", "SALES_REP"]

export const useAppCore = defineStore("corrigo-core", () => {
	const currentUser = ref<UserData | null>(undefined)
	const organization = ref<string>()

	const organizations = ref<{ organization_id: string; name: string }[]>([])

	const config = reactive<{ ui?: UiConfig }>({})

	async function postUserLoad() {
		await Promise.all([loadUiConfig(), loadOrganizations()])
	}

	const extended_admin_controls = useSessionStorage(LS.EXT_ADMIN_CTRLS, false)

	function enableAdminControls(): null {
		extended_admin_controls.value = true
		console.log("extended admin controls enabled")
		return null
	}

	async function reloadUser() {
		const url = new URL(window.location.href)
		const token = url.searchParams.get("auth_token") ?? undefined
		try {
			// let params = new URL(window.location as string).searchParams
			// let name = params.get("name")
			currentUser.value = await USER_API.checkIdentity({ token })
			postUserLoad()
		} catch (e) {
			currentUser.value = null
		} finally {
			if (token) {
				url.searchParams.delete("auth_token")
				window.history.replaceState({ token_claimed: true }, document.title, url.toString())
			}
		}
		return currentUser.value
	}

	const loaded = reloadUser()

	async function loadOrganizations() {
		if (currentUser.value.organization_id) {
			organization.value = currentUser.value.organization_id
		}
		organizations.value = await USER_API.getOrganizationMemberships()
	}

	async function signIn(SignInRequest: SignInOperationRequest["SignInRequest"]) {
		const user = await USER_API.signIn({ SignInRequest })
		currentUser.value = user
		postUserLoad()
	}

	async function loadUiConfig() {
		const conf = await configApi.getUiConfig()
		config.ui = conf
	}
	// async function signIn(AuthParams: { username: string; password: string }) {
	// 	const user = await userApi.signIn({ AuthParams })
	// 	if (user) {
	// 		currentUser.value = user
	// 		// data.logins.load()
	// 	}
	// 	return currentUser.value
	// }

	// co

	async function signOut() {
		await USER_API.signOut()
		currentUser.value = null
	}

	return {
		config,
		state: {
			extended_admin_controls,
			loaded,
			reloadUser,
			currentUser,
			organization,
			organizations,
			isAdmin: computed<boolean>(() => {
				if (currentUser.value) {
					for (const role of currentUser.value.roles) {
						if (ADMIN_ROLES.includes(role)) return true
					}
				}
				return false
			})
		},
		// utils: {},
		actions: {
			reloadUser,
			signIn,
			signOut,
			enableAdminControls
		}
	}
})

export { useAppCore as useStore }
