<script setup lang="ts">
import { ref } from "vue"
import dayjs, { Dayjs } from "dayjs"
import { VLoader } from "@infobex/vue-ui-lib"

import { RTXNET_API } from "@/stores/apis"
import { ModalDateSelector, Pending } from "@/components/ui"

import EmbeddableChartContainer from "../EmbeddableChartContainer.vue"
import Chart from "./Chart.vue"

type DateRange = {
	start_date: Dayjs
	end_date: Dayjs
}

const date = ref(dayjs())

const dateRange = ref<DateRange>({
	start_date: dayjs(),
	end_date: dayjs()
})

RTXNET_API.getI95HistoryAvailability().then(res => {
	dateRange.value.start_date = dayjs(res.start_date)
	dateRange.value.end_date = dayjs(res.end_date)
})

const source = ({ date }: { date: Dayjs }) => {
	return RTXNET_API.getI95History({
		start_date: date.startOf("day").toDate() as unknown as string,
		end_date: date.endOf("day").toDate() as unknown as string
	})
}
</script>
<template>
	<EmbeddableChartContainer title="I95 index">
		<template #controls>
			<ModalDateSelector
				v-model="date"
				:min-date="dayjs(dateRange.start_date)"
				:max-date="dayjs(dateRange.end_date)"
			/>
		</template>
		<template #chart>
			<VLoader v-slot="{ data, pending }" :source="source" :args="{ date }" :fallback="[]">
				<Chart :labels="data.map(d => dayjs(d.t).format('H:mm'))" :values="data.map(d => d.v)" />
				<Pending v-if="pending" />
			</VLoader>
		</template>
		<template #help>
			<p>
				GNSS referenciaállomás-hálózatok esetén elsősorban a légköri fénytörés okozta távolsági torzítások egyedi
				korrekciós módszerei állíthatók elő. Az I95 ionoszféra index ionoszféra modell együtthatókon alapul. Az I95
				index egy statisztikai adat, amely a differenciális ionoszféra torzítások számáról szolgáltat statisztikát,
				azaz egy
				<strong>mértékegység nélküli</strong>
				mutatószám.
			</p>
			<p>
				Az indexet a kétfrekvenciás fix-kétértelműségű fázismérésekből számítják ki, amelyek lehetővé teszik a
				differenciális ionoszféra torzítások meghatározását elég nagy pontossággal. Egyetlen I95 érték egyetlen
				számba egyesíti legalább 2 alapvonal (legalább 3 referenciaállomás) összes elérhető műholdjelének
				differenciális ionoszféra torzítását.
			</p>
			<p>
				Az I95 index értékei nemcsak az ionoszféra körülményeitől függnek, hanem néhány egyéb tényezőtől is, például
				a bázisállomások távolságától és beállított vágási szögüktől.
			</p>

			<ul>
				<strong>Amennyiben az i95:</strong>
				<li>&lt;= 2 kis mennyiségű elektrontartalom, a mérést nem zavarja az ionoszféra állapota</li>
				<li>&lt;= 4 nagy elektrontartalom, de nincs anomália</li>
				<li>&lt;= 10 magas elektrontartalom, anomáliákra kell számítani</li>
			</ul>
		</template>
	</EmbeddableChartContainer>
</template>
