import { computed } from "vue"
import type { RouteRecordNormalized } from "vue-router"
import { useRouter } from "vue-router/auto"

export function useNavbarItems() {
	return useRouter()
		.getRoutes()
		.filter(rt => rt.meta.isNavbarItem)
		.map(__mapRouteToObject)
		.sort((a, b) => {
			return a.index - b.index
		})
}

export function useNavbarGroup(menuRoot?: string) {
	if (menuRoot === undefined) return null
	return useNavbarItems().filter(rt => rt.isMenuItem == menuRoot)
}

function __mapRouteToObject(rt: RouteRecordNormalized) {
	const { meta } = rt
	return {
		...meta,
		route: rt
	}
}
