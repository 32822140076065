<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs"
import { computed, reactive, ref, watch } from "vue"
import { Input } from "@/components/ui"
import { VIcon, VButton } from "@infobex/vue-ui-lib/core"

import hu from "dayjs/locale/hu"
import CalendarDay from "./CalendarDay.vue"

dayjs.locale("hu")
// dayjs.extend(weekday)

const props = defineProps<{
	defaultValue?: Dayjs | null
	title?: string
	name?: string
	modelValue?: Dayjs | null
	maxDate?: Dayjs | Date
	minDate?: Dayjs | Date
	required?: boolean
	viewValue?: Dayjs
	disableDay?: (d: Dayjs, activeDay?: Dayjs | null) => any
	dayClasses?: (d: Dayjs, activeDay?: Dayjs | null) => string | undefined | object
	dayProps?: (d: Dayjs, activeDay?: Dayjs | null) => any
}>()

const emit = defineEmits<{
	(e: "update:modelValue", value: Dayjs | null): void
	(e: "jumpTo", value: Dayjs | null): void
}>()

const inner = ref(props.modelValue ?? props.defaultValue ?? null)

const viewValue = ref(props.viewValue ?? props.modelValue ?? props.defaultValue ?? dayjs())

const currentValue = computed(() => {
	return props.modelValue ?? inner.value
})

const days = computed(() => getCalendarDays(viewValue.value))

watch(
	() => props.viewValue,
	newValue => {
		viewValue.value = newValue
	}
)

function setValue(d?: Dayjs) {
	if (inner.value?.isSame(d)) {
		inner.value = null
		emit("update:modelValue", inner.value)
	} else {
		inner.value = d ?? null
		emit("update:modelValue", d ?? inner.value)
	}
}

function getCalendarDays(base?: Dayjs | null) {
	const start = (base ?? dayjs()).startOf("month").startOf("week") //.add(1, 'day')
	const days: Dayjs[] = []
	for (let i = 0; i < 42; i++) {
		days.push(start.add(i, "day"))
	}
	return days
}

function isDayDisabled(day: Dayjs) {
	return (
		(props.minDate !== undefined && day.isBefore(props.minDate)) ||
		(props.maxDate !== undefined && day.isAfter(props.maxDate)) ||
		Boolean(props.disableDay?.(day))
	)
}

function jumpTo(amount: number, scale: "year" | "month") {
	viewValue.value = viewValue.value.add(amount, scale)
	emit("jumpTo", viewValue.value)
}
</script>
<template>
	<div class="calendar-container">
		<slot />
		<header v-if="title">{{ props.title }}</header>
		<nav>
			<VButton class="icon-btn" type="button" variant="link" @click="jumpTo(-1, 'year')">
				<VIcon i="angles-left" variant="solid" />
			</VButton>
			<VButton class="icon-btn" type="button" variant="link" @click="jumpTo(-1, 'month')">
				<VIcon i="angle-left" variant="solid" />
			</VButton>
			<strong>{{ viewValue.format("YYYY. MMMM") }}</strong>
			<VButton class="icon-btn" type="button" variant="link" @click="jumpTo(1, 'month')">
				<VIcon i="angle-right" variant="solid" />
			</VButton>
			<VButton class="icon-btn" type="button" variant="link" @click="jumpTo(1, 'year')">
				<VIcon i="angles-right" variant="solid" />
			</VButton>
		</nav>
		<main v-auto-animate class="calendar-body">
			<strong>H</strong>
			<strong>K</strong>
			<strong>Sze</strong>
			<strong>Cs</strong>
			<strong>P</strong>
			<strong>Szo</strong>
			<strong>V</strong>
			<template v-for="(day, i) in days" :key="i">
				<slot
					name="day"
					:outside="day.get('month') !== viewValue.get('month')"
					:disabled="day.isBefore(minDate) || day.isAfter(maxDate)"
					:active="day.isSame(currentValue, 'day')"
					:value="day"
					:handle-select="setValue"
				>
					<CalendarDay
						:class="dayClasses?.(day, currentValue)"
						:outside="day.get('month') !== viewValue.get('month')"
						:active="day.isSame(currentValue, 'day')"
						:value="day"
						:disabled="isDayDisabled(day)"
						v-bind="dayProps?.(day, currentValue)"
						@select="setValue"
					/>
				</slot>
			</template>
		</main>
		<Input readonly placeholder="" :model-value="inner?.format('YYYY-MM-DD') ?? ''" :name="name" :required="required">
			<template #suffix>
				<VButton class="icon-btn" type="button" variant="link" color="neutral" @click="() => setValue()">
					<VIcon
						i="xmark"
						variant="solid"
						:class="{ 'opacity-0': !inner }"
						class="text-neutral-500 hover:text-red-500"
					/>
				</VButton>
			</template>
		</Input>
	</div>
</template>
<style lang="scss">
.v-button.icon-btn {
	@apply p-0;
	width: 28px;
	height: 28px;
	// display: block;
}

.calendar-container {
	font-size: 14px;
	width: fit-content;
}

.calendar-container .year-select {
	@apply text-lg;
	@apply px-2 py-2;
	text-align: center;
}

.calendar-container header {
	text-align: center;
	font-size: 1.25em;
	font-weight: 600;
}

.calendar-container nav {
	@apply flex items-center;
	@apply px-2 py-1;
	strong {
		text-transform: capitalize;
		flex-grow: 1;
		text-align: center;
		// @apply bg-primary-600;
	}
}

.calendar-container main {
	position: relative;
	display: grid;
	grid-template-columns: repeat(7, auto);
	grid-template-rows: repeat(7, auto);
	gap: 2px;
	margin-bottom: 8px;
	place-items: center;
	place-content: center;
	@apply text-neutral-800;
	@apply bg-white;

	strong {
		// align-self: stretch;
		display: block;
		text-align: center;
	}
}

// .day-move, /* apply transition to moving elements */
// .day-enter-active,
// .day-leave-active {
// 	transition: all 0.5s ease;
// }

// .day-enter-from,
// .day-leave-to {
// 	opacity: 0;
// 	bottom: 0;
// 	// transform: translateX(30px);
// }

// /* ensure leaving items are taken out of layout flow so that moving
//    animations can be calculated correctly. */
// .day-leave-active {
// 	position: absolute;
// }
</style>
