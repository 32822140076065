<script setup lang="ts">
import { defineProps, computed, ref } from "vue"
import { RTXNET_API } from "@/stores/apis"
import { VLoader, VButton, VIcon } from "@infobex/vue-ui-lib"
import ModalDateRangeSelector from "@/components/ui/ModalDateRangeSelector/Control.vue"
import { Pending } from "@/components/ui"
import dayjs, { Dayjs } from "dayjs"
import Chart from "./Chart.vue"
import EmbeddableChartContainer from "../EmbeddableChartContainer.vue"
import { CHARTS, DATE_FORMAT } from "./constants"

import { stall } from "@/utils/timers"

defineProps<{
	station_code: string
}>()

interface LoaderArgs {
	station_code: string
	start_date: Dayjs
	end_date: Dayjs
}

async function source({ station_code, start_date, end_date }: LoaderArgs) {
	const { displacement } = await stall(
		RTXNET_API.getStationDisplacement({
			station_code: station_code,
			start_date: start_date.toISOString(),
			end_date: end_date.toISOString()
		}),
		600
	)

	displacement.reverse()

	const data = {
		is_empty: displacement.length === 0,
		labels: displacement.map(d => dayjs(d.t).format(DATE_FORMAT)),
		values: {
			abs: displacement.map(d => d.abs_mm),
			rel: displacement.map(d => d.rel_mm),
			x: displacement.map(d => d.xyz_mm[0]),
			y: displacement.map(d => d.xyz_mm[1]),
			z: displacement.map(d => d.xyz_mm[2])
		}
	}

	return data
}

const __selected_chart = ref(CHARTS[0].key)
const selected_chart = computed(() => {
	return CHARTS.find(c => c.key === __selected_chart.value)
})

function setSelectedChart(e: Event, chart_key: string) {
	__selected_chart.value = chart_key
}

const date_range = ref<[Dayjs, Dayjs]>([dayjs().subtract(1, "month"), dayjs()])
</script>

<template>
	<!---->
	<EmbeddableChartContainer title="Elmozdulások">
		<!-- Controls -->
		<template #controls>
			<VButton
				v-for="chart_def in CHARTS"
				:key="chart_def.key"
				:variant="chart_def.key === __selected_chart ? 'filled' : 'subdued'"
				:value="chart_def.key"
				@click="setSelectedChart"
			>
				{{ chart_def.title }}
			</VButton>
			<ModalDateRangeSelector
				v-model="date_range"
				:min-date="dayjs().year(2023).endOf('year')"
				:max-date="dayjs()"
				class="modal"
			/>
		</template>

		<!-- Legend -->
		<template v-if="selected_chart.legend" #legend>
			<p v-for="C in selected_chart.datasets" :key="C.name">
				<VIcon variant="solid" i="circle" size="20" :style="{ color: `rgba(${C.color})` }" />
				{{ C.name }}
			</p>
		</template>

		<!-- Chart -->
		<template #chart>
			<VLoader
				v-slot="{ data, pending }"
				:source="source"
				:args="{ station_code, start_date: date_range[0], end_date: date_range[1] }"
			>
				<Chart v-if="data" :labels="data.labels" :datasets="selected_chart.datasets" :data="data.values" />
				<Pending v-if="pending" />
			</VLoader>
		</template>

		<!-- Help -->
		<template #help>
			<div class="px-10 text-justify text-sm"><p>Ide kerül egy példa szöveg</p></div>
		</template>
	</EmbeddableChartContainer>
</template>

<style lang="scss">
.modal {
	width: 50vh;
}
</style>
