<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs"
import { Card } from ".."
import { VDateSelect, VButton } from "@infobex/vue-ui-lib"
import Calendar from "@/components/ui/Calendar/Calendar.vue"
import { ref } from "vue"

const props = defineProps<{
	minDate?: Dayjs
	maxDate?: Dayjs
	defaultValue?: Dayjs
}>()

const emit = defineEmits<{
	(c: "resolve", v: Dayjs): any
	(c: "reject"): any
}>()

const innerValue = ref(props.defaultValue ?? dayjs())

function resolveDialog() {
	emit("resolve", innerValue.value)
}
</script>
<template>
	<Card class="bg-white gap-4">
		<Calendar v-model="innerValue" name="date" required v-bind="{ minDate, maxDate }">
			<!-- <h6 class="text-xl font-semibold">Kezdődátum:</h6> -->
		</Calendar>
		<VButton color="primary" variant="subdued" @click="resolveDialog">OK</VButton>
	</Card>
</template>
<style lang="scss"></style>
