<script setup lang="ts">
import { RouterView, useRouter } from "vue-router/auto"
import { NavBar } from "@/components/app"
import { useAppCore } from "./stores/core"
import { DashboardNavigation } from "./components/app/DashboardNavigation"
import LoginPage from "@/pages/__static/LoginPage.vue"
import ResetPassword from "./components/forms/ResetPassword.vue"
import Spinner from "./components/ui/Spinner.vue"

import { VLoader } from "@infobex/vue-ui-lib/core"
import { VModalOutlet } from "@infobex/vue-ui-lib/unstable"
import { VThemeProvider } from "@infobex/vue-ui-lib/draft"

import { useConsoleCommands } from "./stores/composables/useConsoleCommands"

import "@/theme"
import { onMounted } from "vue"
// import "@infobex/vue-ui-lib/style.css"

const { state } = useAppCore()

const router = useRouter()

const load = () => state.loaded

onMounted(() => {
	useConsoleCommands()
})
</script>
<template>
	<VThemeProvider
		:theme="{
			iconVariant: 'duotone'
		}"
	>
		<VLoader v-slot="{ loaded }" :source="load">
			<RouterView v-if="state.currentUser" v-slot="{ Component, route }">
				<Suspense :timeout="0">
					<template v-if="state.currentUser">
						<NavBar />
						<ResetPassword v-if="state.currentUser.flags?.require_password_reset" />
						<main v-else id="app-content">
							<DashboardNavigation />
							<div class="page-content">
								<component :is="Component" v-if="route" />
							</div>
						</main>
					</template>
					<template v-else-if="state.currentUser === null">
						<LoginPage />
					</template>
					<template #fallback>
						<Spinner backdrop />
					</template>
				</Suspense>
			</RouterView>
			<LoginPage v-else-if="loaded" />
		</VLoader>

		<VModalOutlet>
			<!-- <template #debug="debugInfo">
				<div class="fixed left-0 bottom-0 bg-red-800 bg-opacity-80 text-white" style="z-index: 9999999">
					{{ debugInfo }}
				</div>
			</template> -->
			<!-- <component
									v-if="dialog"
									:is="dialog.component"
									v-bind="dialog.props"
									@resolve="dialog.onResolve"
									@reject="dialog.onReject"
								/> -->
		</VModalOutlet>
	</VThemeProvider>
</template>
<style src="./App.scss">
.v-dialog {
	&-enter-from,
	&-leave-to {
		position: absolute;
		opacity: 0;
		transition-property: opacity, transform;
		transition-duration: 375ms;
		transform: translate(0, -1rem) scale(0.9);
	}

	&-enter-to,
	&-leave-from {
		position: absolute;
		opacity: 1;
		transition-property: opacity, transform;
		transition-duration: 375ms;
		transform: translate(0, 0) scale(1);
	}
}
</style>
