<script setup lang="ts">
import { VIcon } from "@infobex/vue-ui-lib/core"
const props = defineProps<{
	id?: string
	label?: string
	modelValue?: boolean | undefined | null
	readonly?: boolean
	onChange?: () => any
}>()

const elementId = props.id ?? Math.random().toString(16).slice(2)

const emit = defineEmits(["update:modelValue"])

const updateValue = (event: boolean) => {
	if (props.readonly) return
	emit("update:modelValue", event)
	if (props.onChange) props.onChange()
}

const handleClick = (e: Event) => {
	updateValue(!props.modelValue)
	// console.log(props.modelValue)
}
</script>
<template>
	<div class="tw-checkbox" tabindex="0" :class="{ checked: modelValue }" @click="handleClick">
		<span :id="elementId" class="checkbox-icon" :class="{ checked: modelValue }">
			<VIcon v-show="modelValue" i="check" size="16" />
		</span>
		<label v-if="label" :for="elementId" :class="{ checked: modelValue }">
			{{ label }}
		</label>
	</div>
</template>
<style lang="scss">
.tw-checkbox {
	@apply flex items-center;
	@apply gap-2;
	--size: 20px;
	@apply p-1;
	border-radius: 3px;

	line-height: normal;
	cursor: pointer;

	@apply transition-all;

	&:active .checkbox-icon {
		@apply ring-2 ring-primary ring-opacity-50;
	}

	&:hover {
		@apply bg-black bg-opacity-5;
	}

	&.checked {
		// @apply bg-primary bg-opacity-10;
	}

	label {
		font-weight: 500;
		cursor: pointer;
		// @apply text-xl;
		display: flex;
		align-items: center;
		// font-size: var(--size);
		line-height: var(--size);
		@apply transition-all;
	}

	label.checked {
		// @apply text-primary-dark;
		// @apply translate-x-1;
		// font-weight: 500;
	}
}

.checkbox-icon {
	@apply border border-neutral-400 bg-neutral-200;
	@apply box-border;
	@apply transition-all;
	// @apply inline-block;
	@apply rounded;
	// @apply ring ring-primary-600 ring-opacity-50;
	text-align: center;
	// line-height: 20px;
	width: var(--size);
	height: var(--size);
	@apply flex items-center justify-center;
	// font-weight: 600;

	&.checked {
		@apply bg-primary border-primary;
	}

	i {
		@apply text-white;
	}
}
</style>
