<script setup lang="ts">
import type { Dayjs } from "dayjs"
import { VButton } from "@infobex/vue-ui-lib/core"

const props = defineProps<{
	outside?: boolean
	active?: boolean
	disabled?: boolean
	selected?: boolean
	value: Dayjs
}>()

const emit = defineEmits<{
	(c: "select", v: Dayjs): void
}>()

function handleSelect() {
	emit("select", props.value)
}
</script>
<template>
	<VButton
		v-bind="$attrs"
		type="button"
		class="calendar-day"
		:class="{ outside, active }"
		:title="value.format('YYYY-MM-DD')"
		:disabled="disabled"
		@click="handleSelect"
	>
		<slot>
			{{ value.get("date") }}
		</slot>
	</VButton>
</template>
<style lang="scss">
@mixin day-outside {
	font-weight: 300;
	background-color: transparent;
}

@mixin day-active {
	background-color: rgb(var(--c-primary) / 100%);
	color: rgb(var(--c-primary-text));
}

@mixin day-highlight {
	background-color: rgb(var(--c-primary) / var(--bg-opacity));
	color: rgb(var(--c-primary));
}

@mixin day-disabled {
	@apply line-through cursor-not-allowed font-light;
	// pointer-events: none;
	// color: rgb(var(--c-error));
	opacity: 25%;
}

@mixin day-error {
	background-color: rgb(var(--c-error) / 40%) !important;
	color: red !important;
	font-weight: normal;
	opacity: 100% !important;
	text-decoration: red wavy underline !important;
	cursor: not-allowed !important;
}

.calendar-body {
	button.v-button.calendar-day {
		width: var(--cell-size, 2.5rem);
		height: var(--cell-size, 2.5rem);

		@apply transition-all;
		@apply text-neutral-600;
		@apply text-lg md:text-base;
		@apply bg-neutral-100;
		@apply rounded-none;
		--bg-opacity: 30%;

		&.outside {
			@include day-outside;
		}

		&:disabled {
			@include day-disabled;
		}

		&:hover,
		&.selected,
		&.selected:hover {
			@include day-highlight;
		}

		&.selected {
			--bg-opacity: 20%;
			&:hover {
				--bg-opacity: 30%;
			}

			&:disabled {
				opacity: 100;
				@include day-error();
			}
		}

		&.active {
			@include day-active;
		}

		//daterange-picker styles
		// &:has(~ *.active),
		// &:has(~ *:hover),
		// &:hover ~ *:has(~ *.active),
		// &.active ~ *:has(~ *:hover),
		// &.active ~ *:has(~ *.active) {
		// 	--bg-opacity: 20%;
		// 	@include day-highlight();
		// }
	}
}
</style>
