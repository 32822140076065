<script setup lang="ts">
import { onMounted, ref } from "vue"
import { useAppCore, USER_API } from "../../stores/core"
import { VIcon } from "@infobex/vue-ui-lib/core"
import Input from "../ui/Input.vue"
const { state } = useAppCore()

// const options = ref<
// 	{
// 		organization_id: string
// 		name: string
// 	}[]
// >()

// onMounted(() => {
// 	if (state.currentUser?.organization_id == null) {
// 		userApi.getOrganizationMemberships().then(orgs => {
// 			options.value = orgs
// 		})
// 	}
// })
</script>
<template>
	<Input
		v-if="state.currentUser?.organization_id == null"
		v-model="state.organization"
		type="select"
		class="text-black bg-white"
		placeholder="Szűrés szervezetre"
	>
		<template #options>
			<option v-for="org of state.organizations" :key="org.organization_id" :value="org.organization_id">
				{{ org.name }}
			</option>
		</template>
		<template #prefix>
			<VIcon i="sitemap" />
		</template>
		<template v-if="state.organizations.length > 1" #suffix>
			<VIcon
				v-if="state.organization !== undefined"
				class="cursor-pointer"
				i="xmark"
				@click="
					() => {
						state.organization = undefined
					}
				"
			/>
		</template>
		<template v-else #suffix>
			<VIcon i="lock-keyhole" />
		</template>
	</Input>
</template>
<style lang="scss"></style>
