<script setup lang="ts">
import dayjs, { Dayjs } from "dayjs"

import { VFormControl } from "@infobex/vue-ui-lib"
import { prettyPrintDateRange } from "@/utils/date"
import { useModal } from "@infobex/vue-ui-lib/unstable"
import { VIcon } from "@infobex/vue-ui-lib/core"

import Dialog from "./Dialog.vue"

const props = defineProps<{
	minDate: Dayjs
	maxDate: Dayjs
}>()

const modelValue = defineModel<[Dayjs, Dayjs]>({ default: () => [dayjs().subtract(1, "days"), dayjs()] })

async function openDialog() {
	try {
		const date = await useModal(Dialog, {
			minDate: dayjs().subtract(1, "year").endOf("year"),
			maxDate: dayjs(),
			defaultValue: modelValue.value
		})
		modelValue.value = date
	} catch (e) {
		throw e
	}
}
</script>
<template>
	<div class="flex gap-2 py-4 px-4">
		<VFormControl
			:style="{ width: '20rem' }"
			:model-value="prettyPrintDateRange(modelValue[0], modelValue[1]) ?? '--'"
			name="date"
			@click="openDialog"
		>
			<template #prefix>
				<VIcon i="calendar" />
			</template>
		</VFormControl>
	</div>
</template>
