<script setup lang="ts">
import { useAppCore } from "@/stores/core"
import { useRouter } from "vue-router"
import { VIcon, VButton } from "@infobex/vue-ui-lib/core"

import { useScreenSizes } from "@/utils/responsive"

const { actions, state } = useAppCore()
const router = useRouter()

const { xs, sm, md } = useScreenSizes()
</script>
<template>
	<div class="navbar-userinfo">
		<span class="font-light text-lg flex items-center gap-1">
			<VIcon i="user" variant="solid" />
			<span v-if="md">Belépve:</span>
			<strong>{{ state.currentUser?.name }}</strong>
		</span>
		<VButton color="error" variant="filled" size="md" @click="actions.signOut">
			<template v-if="xs" #default>Kilépés</template>
			<template v-if="xs" #suffix>
				<VIcon i="power-off" variant="solid" />
			</template>
			<VIcon v-if="!xs" i="person-to-door" size="20" variant="solid" />
		</VButton>
	</div>
</template>
<style lang="scss">
.navbar-userinfo {
	@apply flex items-center gap-8;
	// @apply border-l border-white;
	// @apply pl-4;
	@apply md:justify-between md:w-full md:flex-grow;
}
</style>
